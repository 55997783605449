/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import "./Modals.scss";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Cart = ({ ...props }) => {
  const [data, setData] = useState(
    localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
  );
  const [totalValue, setTotalValue] = useState(0);
  const [openInfoUserModal, setOpenInfoUserModal] = useState(false);
  const [loadMath, setLoadMath] = useState(0);
  const { empresa } = useParams();
  const history = useNavigate();

  useEffect(() => {
    setData(
      localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : []
    );
    if (localStorage.getItem("cart")) {
      setTotalValue(0);
      let newValue = 0;
      JSON.parse(localStorage.getItem("cart")).forEach((item) => {
        newValue += item.amount * item.price;
      });
      setTotalValue(newValue);
    }
  }, [props.load]);
  if (totalValue < 0) {
    setTotalValue(0);
  }
  function handleDelete(index) {
    if (localStorage.getItem("cart")) {
      let array = JSON.parse(localStorage.getItem("cart"));
      let oldValue = array[index].amount * data[index].price;
      setTotalValue(totalValue - oldValue);
      array.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(array));

      setData(JSON.parse(localStorage.getItem("cart")));
    }
  }
  function handleMinimumPrice() {
    if (totalValue >= parseFloat(localStorage.getItem("minimum_price"))) {
      history(`/menu/${empresa}/fechar-pedido`);
    } else {
      toast.warning(
        `O valor minimo para compra é de R$ ${parseFloat(
          localStorage.getItem("minimum_price")
        )}`
      );
    }
  }
  return (
    <div id="box-mui-cart">
      <div className="cart-modal">
        <div className="cart-content">
          <p className="sub-title">Resumo do Pedido</p>
          <div className="products">
            {data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <div className="product" key={index}>
                    <div className="first-box">
                      <p className="info-product">
                        <span>{item.amount}x</span> <span>{item.name}</span>
                        <br />
                        <span style={{ fontWeight: "650" }}>
                          R$ {(item.price - item.additionalsPrice).toFixed(2)}
                        </span>
                        <br />
                        {item.additionals?.map((subItem) => (
                          <>
                            <span className="info-product-additional">
                              <span>{subItem?.amount * item.amount}x</span>
                              <span>{subItem?.name}</span>
                              <span style={{ fontWeight: "650" }}>
                                R$ {subItem?.price?.toFixed(2)}
                              </span>
                            </span>
                          </>
                        ))}
                        <div className="info-product-additional-subtotal">
                          <span>SubTotal:</span>{" "}
                          <span style={{ fontWeight: "650" }}>
                            R$ {(item.amount * item.price).toFixed(2)}
                          </span>
                        </div>
                      </p>

                      <img
                        onError={(e) => {
                          e.target.src = 'url-da-imagem-de-fallback';
                          e.target.style.objectFit = 'cover';
                        }}
                        style={{ maxWidth: '30%', height: 'auto' }}
                        alt=""
                        loading="lazy"
                        crossOrigin="anonymous"
                        src={process.env.REACT_APP_API_URL + `/${item.image}`}
                      />
                    </div>

                    <div
                      className="second-box"
                      onClick={() => handleDelete(index)}
                    >
                      <i class="fa-regular fa-trash-can"></i>{" "}
                    </div>
                    {item.observation ? (
                      <p className="cart-observation">
                        <span style={{ fontWeight: "bolder" }}>
                          Observação:
                        </span>
                        <br />
                        <p>{item.observation}</p>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                <p className="has-no text-center">
                  Não há produtos no seu carrinho
                </p>
              </>
            )}
          </div>
          <div className="box-total">
            <p>Total:</p>
            <p>R$ {totalValue.toFixed(2)}</p>
          </div>
          <div className="box-total">
            <p>Valor Minimo:</p>
            <p>
              R$ {parseFloat(localStorage.getItem("minimum_price"))?.toFixed(2)}
            </p>
          </div>
          <div className="box-button">
            <button
              className="back"
              onClick={() => history(`/menu/${empresa}`)}
            >
              Continuar Comprando
            </button>
            <button className="save" onClick={() => handleMinimumPrice()}>
              Finalizar Pedido{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
