/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { Box, Modal } from "@mui/material";
import "./Modals.scss";
import InputMask from "react-input-mask";
import { createUser, getAddress, getUser } from "../../helpers/Api";
import { toast } from "react-toastify";
import { Context } from "../../authentication/AuthContext";

const IdentifyUserModal = ({ ...props }) => {
  const [cellPhone, setCellPhone] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(true);
  const { usuarioConsumidor, setUsuarioConsumidor, enderecoUsuarioConsumidor, setEnderecoUsuarioConsumidor } = useContext(Context);


  function handleGetDataUser(e) {
    setCellPhone(e);
    if (!e.includes("_")) {
      getUser(e)
        .then((res) => {
          if (res) {
            localStorage.setItem("consumerUser", JSON.stringify(res.data.user));
            setUsuarioConsumidor(res.data.user);
            setName(res.data.user.nome);
            setCellPhone(res.data.user.telefone);
          };
        })
        .catch((error) => {
          if (error.response.status) {
          }
        })
        .finally(() => { });

      getAddress(e)
        .then((res) => {
          if (res) {
            setEnderecoUsuarioConsumidor(res.data.data)
          };
        })
        .catch((error) => {
          if (error.response.status) {
          }
        })
        .finally(() => { });
    }
  }
  const org = localStorage.getItem("cod_org")
  function handleCreateUser() {
    if (cellPhone.length < 14 || cellPhone.includes("_")) {
      return toast.error("Insira um número de telefone válido");
    }
    if (name.length < 5) {
      return toast.error("Nome deve ter no minímo 5 caracteres");
    }
    let data = {
      telefone: cellPhone,
      nome: name,
    };
    setLoading(true);

    createUser(data, org)
      .then((res) => {
        if (res.data) {
          setUsuarioConsumidor(res.data.user);
          localStorage.setItem("consumerUser", JSON.stringify(res.data.user));
          props.setModalIdentifyFalse();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    if (usuarioConsumidor) {
      setEditData();
      setName(usuarioConsumidor.nome);
      setCellPhone(usuarioConsumidor.telefone);
      setEditData(false);
    };
    if (enderecoUsuarioConsumidor) {

    }
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => []}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <Box id="box-mui-identify" className="">
          <div className="identify-modal">
            {editData || props.edit.includes("edit") ? (
              <>
                <p className="identify-title">Identifique-se</p>
                <label className="form-control mt-5">
                  Número de Telefone
                  <InputMask
                    mask="(99)99999-9999"
                    placeholder="Ex: (11)99342-3247"
                    onChange={(e) => [handleGetDataUser(e.target.value)]}
                    autoComplete="off"
                    value={cellPhone}
                  />
                </label>
                <label className="form-control mt-4">
                  Nome e Sobrenome
                  <input
                    className=""
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ex: João Souza"
                  />
                </label>
              </>
            ) : (
              <>
                <p className="identify-title fw-bolder text-center">
                  Confirme seus dados
                </p>
                <p className="fw-bold text-center">{name}</p>
                <p className="fw-bold text-center">{cellPhone}</p>
              </>
            )}
            <div className="box-button">
              {props.edit.includes("edit") || editData ? (
                <></>
              ) : (
                <button className="back" onClick={() => setEditData(true)}>
                  Editar
                </button>
              )}
              <button
                className="save"
                onClick={() => [
                  editData || props.edit.includes("edit")
                    ? handleCreateUser()
                    : props.setModalIdentifyFalse(),
                  props.editModalFalse(),
                ]}
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Salvar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default IdentifyUserModal;
