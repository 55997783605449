import React, { useEffect, useState } from "react";
import "./FecharPedidos.scss";
import { closeOrder, getPaymentMethod } from "../../../components/helpers/Api";
import { formatarMoeda } from "../../../utils/utils";
import moment from "moment";
import { data } from "jquery";

const FecharPedidos = ({ ...props }) => {
  const [totalValue, setTotalValue] = useState(0);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [orderNumber, setOrderNumber] = useState("");
  const [codTable, setCodTable] = useState("");
  const [discount, setDiscount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [remaining, setRemaining] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentMethodsWithValue, setPaymentMethodsWithValue] = useState([]);
  const [totalValuePg, setTotalValuePg] = useState(0);
  const [troco, setTroco] = useState(0);


  useEffect(() => {
    setOrderNumber(props?.data?.numero_pedidomesa);
    setCodTable(props?.data?.cod_mesa);
    setTotalValue(props?.data?.valor_total);

    setTotalValuePg(props.data?.troco > 0 ? props.data?.troco : props?.data?.valor_total)
    handleGetPaymentMethods();
  }, [props.data]);

  function handleGetPaymentMethods() {
    getPaymentMethod("", 1)
      .then((res) => {
        setPaymentMethod(res.data.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  }
  function handleCash(event, index) {
    let value = formatarMoeda(event);
    value = value.replace(".", "").replace(",", ".");
    value = parseFloat(value) || 0;

    let updatedMethods = [...paymentMethodsWithValue];
    updatedMethods[index] = { ...updatedMethods[index], value };
    setPaymentMethodsWithValue(updatedMethods);

    updateTotalPaid(updatedMethods);
  }
  function updateTotalPaid(updatedMethods = paymentMethodsWithValue) {
    let total = updatedMethods.reduce((acc, item) => acc + (parseFloat(item.value) || 0), 0);
    if (total > 0) {
      setTotalValuePg(total);
    }
  }

  useEffect(() => {
    updateTotalPaid();
  }, [paymentMethodsWithValue]);

  useEffect(() => {
    let newTroco = totalValuePg - (totalValue - discount);
    setTroco(newTroco >= 0 ? newTroco : troco);
    setRemaining(totalValue - discount - totalValuePg > 0 ? totalValue - discount - totalValuePg : 0);
  }, [totalValuePg, discount, totalValue]);
  function handleDiscount(event) {
    let value = formatarMoeda(event);
    value = value.replace(".", "");
    value = value.replace(",", ".");
    if (value > 0) {
      setDiscount(value);
    } else {
      setDiscount(0);
    }
  }
  function handleRemaining(event) {
    let value = formatarMoeda(event);
    value = value.replace(".", "");
    value = value.replace(",", ".");
    if (value > 0) {
      setRemaining(value);
    } else {
      setRemaining(0);
    }
  }

  useEffect(() => {
    paymentMethod.forEach((item) => {
      item.value = 0;
    });
    setPaymentMethodsWithValue(paymentMethod);
  }, [paymentMethod]);

  function handleCloseOrder() {
    const data = {
      cod_org: localStorage.getItem("cod_org"),
      valor_total: totalValue,
      discount: discount,
      date: date,
      cod_pk_cliente: props?.data?.cod_pk_cliente,
      troco: props.data?.troco ? props.data?.troco : remaining,
      cod_pedidomesa: props?.data?.cod_pedidomesa,
      cod_mesa: props?.data?.cod_mesa,
      paymentMethods: JSON.stringify(paymentMethodsWithValue),
    };
    setLoading(true);

    closeOrder(props.data.cod_pedidomesa, data)
      .then((res) => {
        props.setClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  // useEffect(() => { setTotalValuePg(props?.data?.valor_total + props.data?.troco) }, [])

  return (
    <main className="close-order">
      <section className="section-one">
        <p className="title">Fechar pedido</p>
        <button onClick={() => props.setClose()} className="save">
          Voltar
        </button>
      </section>
      <section className="section-two">
        <div className="aside-left">
          <div className="input-box">
            <label htmlFor="" className="form-label">
              Valor Total
              <input
                type="number"
                value={totalValue?.toFixed(2)}
                onChange={(e) => setTotalValue(parseFloat(e.target.value))}
                className="form-control"
              />
            </label>
            <label htmlFor="" className="form-label">
              Data
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="form-control"
              />
            </label>
          </div>
          <div className="order-box">
            <p htmlFor="">Número Pedido: #{orderNumber}</p>
            <p htmlFor="">Código Mesa: {codTable}</p>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Quantidade</th>
                <th>Valor Unitário</th>
                <th>Valor Total</th>
              </tr>
            </thead>
            <tbody>
              {props?.data?.tb_ipedidomesas?.map((item, index) => (
                <tr key={index}>
                  <td>{item.cod_ipedidomesa}</td>
                  <td>{item.descricao}</td>
                  <td>{item.quantidade?.toFixed(2)}</td>
                  <td>{item.valor_unitario?.toFixed(2)}</td>
                  <td>{item.valor_total?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="aside-right">
          <label htmlFor="" className="discount form-label w-100">
            Desconto:
            <input
              type="text"
              className="form-control"
              defaultValue={discount}
              min={0}
              onChange={handleDiscount}
            />
          </label>
          <table className="table">
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {paymentMethod?.map((item, index) => (
                <tr key={item.cod_form_pgto}>
                  <td>{item.descricao}</td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      min={0}
                      defaultValue={
                        props.data?.tb_ipedidomesas?.[0]?.forma_pagamento === item.descricao
                          ? item.descricao === "Dinheiro" && props.data?.troco
                            ? (parseFloat(props.data?.tb_ipedidomesas?.[0]?.valor_total + props.data?.valor_servico)).toFixed(2)
                            : parseFloat(props.data?.tb_ipedidomesas?.[0]?.valor_total + props.data?.valor_servico).toFixed(2)
                          : (0).toFixed(2)
                      }
                      // value={paymentMethodsWithValue[index]?.value || "0.00"}
                      onChange={(e) => handleCash(e, index)}
                    />
                  </td>
                </tr>
              ))}



            </tbody>
          </table>
          <label htmlFor="" className="form-control">

            Troco:{troco != 0 ? (troco.toFixed(2)) : discount > 0 ?
              (
                parseFloat(totalValue) -
                parseFloat(discount)
              ).toFixed(2) === 0
                ? 0
                :

                (parseFloat(totalValue) -
                  parseFloat(discount)
                ).toFixed(2) : troco}
          </label>
          <div className="details">
            <p>
              Tot. Pg:{" "}
              <span style={{ color: "blue" }}>{totalValuePg.toFixed(2)}</span>
            </p>
            <p>
              Falta:{" "}
              <span style={{ color: "red" }}>
                {(totalValue + troco + - discount - totalValuePg).toFixed(2) > 0 ? (totalValue + troco + - discount - totalValuePg).toFixed(2) : 0}
              </span>

            </p>
          </div>
        </div>
      </section>
      <section className="section-three">
        <button className="save w-100" onClick={() => handleCloseOrder()}>
          {!loading && <span className="indicator-label">Fechar Pedido</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </section>
    </main>
  );
};

export default FecharPedidos;
