import React from 'react';

const Receipt = React.forwardRef(({ pedido }, ref) => (
    <div
        ref={ref}
        style={{
            width: '80mm',
            fontFamily: 'Tahoma, Geneva, sans-serif',
            fontSize: '10px',
            padding: '10px',
            color: '#0F0F0F',
            fontWeight: 'bold', // Aplica negrito globalmente
        }}
    >
        {pedido ? (
            <>
                <div style={{ textAlign: 'center' }}>
                    <p><strong>Ped. No.: {pedido.numero_pedidomesa}</strong></p>
                    <p><strong>Data: {new Date(pedido.createdAt).toLocaleDateString()}</strong></p>
                </div>
                <hr />
                <div>
                    <p><strong>Mesa: {pedido.cod_mesa}</strong></p>
                    <p><strong>Atendente: {pedido.cod_funcionario || 'BALCAO'}</strong></p>
                </div>
                <hr />
                <div>
                    {pedido.tb_ipedidomesas && pedido.tb_ipedidomesas.length > 0 ? (
                        pedido.tb_ipedidomesas.map((item, index) => (
                            <div key={index}>
                                <p><strong>Qtde  Produto</strong></p>
                                <p><strong>{item.quantidade}  {item.descricao}</strong></p>
                                {item.tb_ipedidomesaacoms && item.tb_ipedidomesaacoms.length > 0 && (
                                    <div>
                                        <p><strong>Sabores:</strong></p>
                                        {item.tb_ipedidomesaacoms.map((acomp, acompIndex) => (
                                            <p key={acompIndex}><strong>- {acomp.descricao}</strong></p>
                                        ))}
                                    </div>
                                )}
                                <hr />
                            </div>
                        ))
                    ) : (
                        <p><strong>Nenhum item encontrado</strong></p>
                    )}
                </div>
            </>
        ) : (
            <p><strong>Carregando...</strong></p>
        )}
    </div>
));

export default Receipt;

