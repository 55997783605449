import axios from "axios";
import { toast } from "react-toastify";


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      let empresa = localStorage.getItem("empresa")
      localStorage.removeItem("token");
      localStorage.removeItem("sistemacemail");
      toast.warning("token expirado!!");
      setTimeout(() => {
        window.location.href = `/${empresa}/auth/login`;
      }, 1500);
      return error;
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
